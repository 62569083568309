@font-face {
  font-family: 'Instrumentarium';
  src:
    url('./InstrumentariumBeta06-Regular.woff2') format('woff2'),
    url('./InstrumentariumBeta06-Regular.woff') format('woff'),
    url('./InstrumentariumBeta06-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Instrumentarium';
  src:
    url('./InstrumentariumBeta06-Bold.woff2') format('woff2'),
    url('./InstrumentariumBeta06-Bold.woff') format('woff'),
    url('./InstrumentariumBeta06-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}
